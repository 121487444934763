<template>
  <div>
    <WsMain>
      <WsReadSection
        :id="_id"
        :modelName="modelName"
        :label="label"
        :fields="fields"
        :leftFields="leftFields"
        :rightFields="rightFields"
        v-model="modelData.shop_class"
      ></WsReadSection>
      <WsCrud
        v-if="modelData.shop_class"
        class="mt-40"
        :orderBySq="true"
        :modelName="$store.state.stone_model.shop_subclass.modelName"
        :label="$store.state.stone_model.shop_subclass.label"
        :fields="$store.state.stone_model.shop_subclass.fields"
        :showFields="showFields.shop_subclass"
        :infiniteScroll="true"
        :params="_params"
        :pageMode="true"
        :dialogCreate="true"
        :dialogUpdate="true"
        :inRowBtnRead="true"
        :createData="_params"
        :updateData="_params"
        :createHideFields="createHideFields.shop_subclass"
        :updateHideFields="updateHideFields.shop_subclass"
        :customTableActions="customTableActions.shop_subclass"
        @custom-table-action="$_onCustomTableAction($event)"
      ></WsCrud>
    </WsMain>
    <WsIndexBelongsToMany
      :belongsToManyModelName="$store.state.stone_model.shop_product.modelName"
      :belongsToManyFields="$store.state.stone_model.shop_product.fields"
      :belongsToManyShowFields="belongsToMany.showFields"
      :belongsToManyPickShowFields="belongsToMany.pickShowFields"
      :belongsToManyPickFilterSelects="_belongsToManyPickFilterSelects"
      :modelName="$store.state.stone_model.shop_class.modelName"
      :id="$route.params.id"
      paramsKey="shop_classes"
      updateBtnLabel="管理商品"
      label="商品"
    ></WsIndexBelongsToMany>
    <WsDialogBelongsToMany
      ref="WsDialogBelongsToMany"
      :belongsToManyModelName="$store.state.stone_model.shop_product.modelName"
      :belongsToManyFields="$store.state.stone_model.shop_product.fields"
      :belongsToManyShowFields="belongsToMany.showFields"
      :belongsToManyPickShowFields="belongsToMany.pickShowFields"
      :belongsToManyPickFilterSelects="_belongsToManyPickFilterSelects"
      :modelName="$store.state.stone_model.shop_subclass.modelName"
      :id="belongsToMany.id"
      :label="belongsToMany.label"
      paramsKey="shop_subclasses"
      updateBtnLabel="管理商品"
    ></WsDialogBelongsToMany>
  </div>
</template>

<script>
import model from "@/__vue2stone_cms/models/shop_class";
export default {
  methods: {
    $_onCustomTableAction($event) {
      if ($event.emit == "shop_product") {
        this.belongsToMany.id = $event.data.item.id;
        this.belongsToMany.label = `商品子分類 - ${$event.data.item.name}`;
        setTimeout(() => {
          this.$refs.WsDialogBelongsToMany.open();
        }, 0);
      }
    },
  },
  data() {
    return {
      belongsToMany: {
        id: null,
        label: null,
        showFields: ["no", "name", "spec"],
        pickShowFields: ["no", "name", "spec", "is_active", "price"],
      },
      modelName: model.modelName,
      label: model.label,
      fields: model.fields,
      leftFields: ["name", "order_type", "icon"],
      rightFields: ["id", "sq"],
      modelData: {
        shop_class: null,
      },
      showFields: {
        shop_subclass: ["id", "name", "is_active"],
      },
      createHideFields: {
        shop_subclass: ["type", "shop_class", "icon"],
      },
      updateHideFields: {
        shop_subclass: ["type", "shop_class", "icon"],
      },
      customTableActions: {
        shop_subclass: [
          {
            icon: "icon-md-reorder",
            emit: "order",
            tooltip: this.$t("排序"),
            getUrl: (item) => {
              return `/shop_subclass/${item.id}/shop_product/order`;
            },
          },
          {
            icon: "icon-md-view-list",
            emit: "shop_product",
            tooltip: "管理商品",
          },
        ],
      },
    };
  },

  computed: {
    _belongsToManyPickFilterSelects() {
      const _belongsToManyPickFilterSelects = {};
      _belongsToManyPickFilterSelects.featured_classes =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.featured_classes;
      _belongsToManyPickFilterSelects.shop_classes =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.shop_classes;
      _belongsToManyPickFilterSelects.shop_subclasses =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.shop_subclasses;
      _belongsToManyPickFilterSelects.is_active =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.is_active;
      return _belongsToManyPickFilterSelects;
    },
    // _shopSubclassFields() {
    //   const _shopSubclassFields = {
    //     ...this.$store.state.stone_model.shop_subclass.fields,
    //   };
    //   delete _shopSubclassFields[]
    // },
    _params() {
      return {
        shop_class: this._id,
      };
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>